.welcome-page {
  display: flex;
  height: 100vh;
  background-color: white;
}
.welcome-page .welcome-page-left {
  width: 50%;
}
.welcome-page .welcome-page-left img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center right;
}
.welcome-page .welcome-page-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 40px;
}
.welcome-page .welcome-page-right h1 {
  color: #4389e9;
  font-size: 34px;
}
.welcome-page .welcome-page-right p {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.6em;
}
.welcome-page .welcome-page-right button {
  margin: 40px auto;
}
