.question-container {
  background-color: white;
  width: 90%;
  min-width: 600px;
  border-radius: 25px;
  padding: 40px;
  margin: 60px auto;
  margin-bottom: 0;
  height: 65vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  position: relative;
}
.question-container h1 {
  margin-top: 0;
}
.question-container h4 {
  color: #7976e6;
  font-size: 28px;
  margin: 0;
}
.question-container h3 {
  font-size: 24px;
  margin: 0;
}
.question-container .label {
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
  font-size: 20px;
  color: #e54e4e;
  font-weight: bold;
}
.question-container .time-circle {
  height: 130px;
  width: 130px;
  border-radius: 50%;
  background-color: #f5881f;
  border: 20px solid #f8ac62;
  -webkit-box-shadow: 0px 0px 0px 20px #f8d544;
  box-shadow: 0px 0px 0px 20px #f8eec4;
  color: #f8eec4;
  margin: 35px auto;
  display: flex;
  position: relative;
  transition-duration: 0.3s;
}
.question-container .time-circle.red {
  background-color: #bb0606;
  border-color: #cf5151;
  -webkit-box-shadow: 0px 0px 0px 20px #ebb4b4;
  box-shadow: 0px 0px 0px 20px #ebb4b4;
  color: #ebb4b4;
}
.question-container .time-circle.green {
  background-color: #4bb73d;
  border-color: #81cd77;
  -webkit-box-shadow: 0px 0px 0px 20px #c9e9c5;
  box-shadow: 0px 0px 0px 20px #c9e9c5;
}

.question-container .time-circle.recording {
  animation-name: recording;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.question-container .time-circle > div {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}
.question-container .time-circle > div > svg {
  font-size: 40px;
}
.question-container .time-circle > div > p {
  margin: 0;
}

.question-container .question {
  text-align: left;
  max-width: 600px;
}

.question-instruction-wrapper {
  display: flex;
  justify-content: space-around;
  margin-bottom: 40px;
}
.question-instruction-wrapper > div {
  max-width: 350px;
  width: 30%;
  font-size: 20px;
}
.question-instruction-wrapper > div > p {
  height: 100px;
}
.question-instruction-wrapper > div > img {
  width: 150px;
}
.question-container .prepare-timer,
.question-container .recording-timer,
.question-container .recording-completed {
  font-size: 24px;
  line-height: 1.4rem;
}
.p-span {
  font-size: 18px;
  font-weight: bold;
  line-height: 2.6rem;
}
.question-container .prepare-timer {
  color: #f5881f;
}
.question-container .recording-timer {
  color: #bb0606;
}
.question-container .recording-completed {
  color: #4bb73d;
}

@keyframes recording {
  0% {
    border-width: 20px;
    -webkit-box-shadow: 0px 0px 0px 20px;
    box-shadow: 0px 0px 0px 20px;
  }
  25% {
  }
  50% {
    border-width: 10px;
    -webkit-box-shadow: 0px 0px 0px 10px;
    box-shadow: 0px 0px 0px 10px;
  }
  75% {
  }
  100% {
    border-width: 20px;
    -webkit-box-shadow: 0px 0px 0px 20px;
    box-shadow: 0px 0px 0px 20px;
  }
}
