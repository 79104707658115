.writing-test-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 5%;
}
.writing-test-header h2 {
  width: 80%;
  text-align: left;
}
.writing-test-timer-icon {
  margin-right: 10px;
  color: #6878f0;
}
.writing-test-timer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  background-color: white;
  border: 3px solid #6878f0;
  border-radius: 10px;
  padding: 10px;
  font-size: 22px;
  margin-left: 40px;
}
.writing-test-timer.red {
  border-color: red;
  color: red;
}
.writing-test-timer.red svg {
  border-color: red;
  color: red;
}

.writing-test-input {
  position: relative;
}
.writing-test-textarea {
  background-color: white;
  border: none;
  border-radius: 25px;
  padding: 40px;
  width: 90%;
  min-width: 600px;
  font-size: 20px;
  margin-bottom: 40px;
}
.writing-test-textarea:disabled {
  background-color: lightgray;
}
.writing-test-input .word-count {
  position: absolute;
  right: 6%;
  bottom: 1%;
  color: gray;
}
.writing-test-wrapper {
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
}
.writing-test-heading {
  text-align: left;
}
.writing-test-heading h3 {
  font-size: 20px;
}
.writing-test-heading h4 {
  color: #7976e6;
  font-size: 26px;
  margin-bottom: 0;
}
#alert-dialog-description {
  text-align: center;
}

.writing-confirm-submit-wrapper .MuiDialog-paper {
  background-color: #f6f7fb;
  padding: 30px;
}
.writing-confirm-submit-wrapper .MuiDialog-scrollPaper {
  padding: 40px;
}
.writing-confirm-submit-wrapper p {
  font-weight: bold;
  font-size: 24px;
  color: #333334;
}

.writing-confirm-submit-wrapper span {
  font-weight: bold;
  font-size: 24px;
  color: #333334;
}
