* {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
  background-color: #f6f7fb;
  overflow: hidden;
  color: #49494b;
}

a {
  color: inherit;
  text-decoration: none;
}

.main-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  background-color: #f6f7fb;
}
.main-content h1 {
  color: #7976e6;
  font-size: 38px;
  margin: 40px auto;
}
.main-content h2 {
  color: #7976e6;
  font-size: 32px;
}
.main-content p {
  font-size: 18px;
  font-weight: bold;
}
.button {
  background-image: linear-gradient(90deg, #7969e6, #40bbf7);
}
.button:disabled {
  background: white;
}

.declaration-form,
.test-guidelines-list {
  max-width: 90%;
  text-align: left;
  margin: 0 auto;
  font-size: 18px;
}
.circular-progress {
  height: 420px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.center {
  text-align: center;
}
.test-guidelines-list {
  line-height: 2em;
}
.productive-skill-test-rules {
  max-width: 600px;
  text-align: left;
  margin: 40px auto;
}
.productive-skill-test-rules li {
  padding-bottom: 10px;
}

.description-page {
  display: flex;
  height: 100vh;
  background-color: white;
}
.description-page .description-page-left {
  width: 50%;
}
.description-page .description-page-left img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center right;
}
.description-page .description-page-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 40px;
  text-align: left;
}
.description-page .description-page-right span {
  color: #7976e6;
  font-size: 20px;
}
.description-page .description-page-right h1 {
  color: #7976e6;
  font-size: 40px;
  margin: 0;
  margin-bottom: 20px;
}
.description-page .description-page-right ul {
  padding: 0 20px;
}
.description-page .description-page-right ul li {
  line-height: 2rem;
  font-size: 20px;
  padding-bottom: 20px;
}
.description-page .description-page-right button {
  margin: 60px auto;
}
.privacy-link {
  color: #7976e6;
  text-decoration: underline;
}
