.header {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  margin: auto;
  -webkit-box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.2);
  background-color: white;
  position: relative;
  z-index: 99;
}

.header-logo {
  display: flex;
}
.header-logo a {
  margin: auto;
}
.header-logo img {
  height: 60px;
  width: auto;
}
.header h1 {
  text-align: center;
}

.header-login {
  display: flex;
  justify-content: center;
}
.header-login button,
.header-login .header-name {
  margin: auto 10px;
}
.header-name {
  color: #7976e6;
  font-weight: bold;
}
