.equipment-cards-container {
  display: flex;
  justify-content: center;
  text-align: center;
}
.equipment-card {
  width: 30%;
  height: 45vh;
  background-color: white;
  border-radius: 25px;
  margin: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.equipment-card h2 {
  font-size: 26px;
}
.equipment-card .equipment-card-icon {
  position: absolute;
  background-color: red;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: -10px;
  top: -10px;
}
.equipment-card .equipment-card-icon.pass {
  background-color: #008000;
}
.equipment-card .equipment-card-icon {
  color: white;
  font-weight: bold;
  font-size: 25px;
}
.audio-recorder,
audio {
  margin: auto;
}
.equipment-button {
  margin: auto;
}
.equipment-card .time-circle {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background-color: #e15b37;
  border: 10px solid #ea8c73;
  -webkit-box-shadow: 0px 0px 0px 10px #f6cec3;
  box-shadow: 0px 0px 0px 10px #f6cec3;
  margin: auto;
  display: flex;
  position: relative;
}

.equipment-card .time-circle.recording {
  animation-name: equipment-recording;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes equipment-recording {
  0% {
    border-width: 10px;
    -webkit-box-shadow: 0px 0px 0px 10px #f6cec3;
    box-shadow: 0px 0px 0px 10px #f6cec3;
  }
  25% {
  }
  50% {
    border-width: 5px;
    -webkit-box-shadow: 0px 0px 0px 5px #f6cec3;
    box-shadow: 0px 0px 0px 5px #f6cec3;
  }
  75% {
  }
  100% {
    border-width: 10px;
    -webkit-box-shadow: 0px 0px 0px 10px #f6cec3;
    box-shadow: 0px 0px 0px 10px #f6cec3;
  }
}

.equipment-card .time-circle > p {
  margin: auto;
  color: white;
  font-size: 28px;
}
