.test-progress-bar h3 {
  color: #6878f0;
}

.test-progress-bar .cell-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
.test-progress-bar .cell {
  width: 60px;
  height: 8px;
  border: 1px solid #6878f0;
  border-radius: 5px;
  margin: 5px;
  transition-duration: 0.5s;
}
.test-progress-bar .cell.finished {
  background-image: linear-gradient(90deg, #7969e6, #40bbf7);
}
.test-progress-bar .cell.current {
  animation-name: answering;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transition-duration: 0.5s;
}

@keyframes answering {
  0% {
    background-color: #afa5f0;
  }
  50% {
    background-color: #8cd6fa;
  }
  100% {
    background-color: #afa5f0;
  }
}
